<script lang="ts" setup>
let keyframes = ''
const target = ref<Element>()

const items = [
  {
    color: 'bg-gradient-lightblue ',
    width: 'w-full md:w-2/4',
    opacity: 'opacity-40',
    animationId: '',
    top: 'top-1/3 md:top-0',
    right: 'md:right-1/3',
    delay: ''
  },
  {
    color: 'bg-gradient border border-purple',
    width: 'w-5/6 md:w-2/4',
    opacity: 'opacity-70',
    animationId: '',
    top: 'top-1/3 md:top-8',
    right: 'right-4 md:right-1/2',
    delay: 'delay-200'
  }
]

const getMinMaxInt = (min: number, max: number) => {
  return Math.random() * (max - min) + min
}

items?.forEach((item, index) => {
  item.animationId = `blobAnimation-${index}`
  const randomScaleX = getMinMaxInt(0.5, 1.5)
  const randomScaleY = getMinMaxInt(0.5, 1.5)
  const animation = `
  @keyframes ${item.animationId} {
    from, to  {
        transform: rotate(0) scale(1,1);
        border-radius: ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% / ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}%;
        }

        35%  {
        transform: rotate(-${getMinMaxInt(20, 90)}deg) scale(${randomScaleX},${randomScaleX});
        border-radius: ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% / ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}%;
        }
        65%  {
        transform: rotate(${getMinMaxInt(20, 90)}deg) scale(${randomScaleY},${randomScaleX});
        border-radius: ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% / ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}% ${getMinMaxInt(45, 90)}%;
        }
  }
`

  keyframes += animation
})
</script>

<template>
  <div class="w-full h-full relative" ref="target">
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="`${item.color} ${item.width} ${item.opacity} ${item.top} ${item.right} ${item.delay}`"
      :style="`animation-name: ${item.animationId}; animation-duration:${index * 2 + 18}s`"
      class="aspect-square bouble absolute blur-2xl"
    ></div>
    <ClientOnly>
      <component :is="'style'">{{ keyframes }}</component>
    </ClientOnly>
  </div>
</template>

<style scoped>
.bouble {
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
</style>
